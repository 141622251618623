import React from "react"
import styled from "styled-components"

const StyledAnchor = styled.a`
  display: block;
  position: relative;
  top: calc(-${props => props.theme.header.height.sm} - 1rem);
  visibility: hidden;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    top: calc(-${props => props.theme.header.height.lg} - 3rem);
  }
`

const Anchor = ({ id, ...props }) => <StyledAnchor id={id}>{` `}</StyledAnchor>

export default Anchor
