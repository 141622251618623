import React from "react"
import { ThemeProvider } from "styled-components"
import { Grid, Row, Col } from "react-styled-flexboxgrid"
import Gallery from "react-grid-gallery"
import { Link } from "gatsby"
import styled from "styled-components"

import { Layout, Section } from "../components/layout"
import Seo from "../components/Seo"
import siteTheme from "../constants/siteTheme"
import heroBackgroundImage from "../images/compressed/window-cleaning-edmonton-hero.jpg"
import PrimaryHeading from "../components/typography/PrimaryHeading"
import Hero from "../components/Hero"
import {
  SecondaryHeading,
  TertiaryHeading,
  BodyText,
} from "../components/typography"
import CheckedItem from "../components/CheckedItem"
import Anchor from "../components/Anchor"
import Button from "../components/Button"
import Sticky from "../components/Sticky"
import { StyledTertiaryHeading } from "../components/typography/TertiaryHeading"
import windows1 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-1.jpg"
import windows2 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-2.jpg"
import windows3 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-3.jpg"
import windows4 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-4.jpg"
import windows5 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-5.jpg"
import windows6 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-6.jpg"
import windows7 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-7.jpg"
import windows8 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-8.jpg"
import windows9 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-9.jpg"
import windows10 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-10.jpg"
import windows11 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-11.jpg"
import windows12 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-12.jpg"
import windows13 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-13.jpg"
import windows14 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-14.jpg"
import windows15 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-15.jpg"
import windows16 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-16.jpg"
import windows17 from "../images/compressed/window-cleaning/full-size/edmonton-window-cleaning-17.jpg"
import windowsThumbnail1 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-1.jpg"
import windowsThumbnail2 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-2.jpg"
import windowsThumbnail3 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-3.jpg"
import windowsThumbnail4 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-4.jpg"
import windowsThumbnail5 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-5.jpg"
import windowsThumbnail6 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-6.jpg"
import windowsThumbnail7 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-7.jpg"
import windowsThumbnail8 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-8.jpg"
import windowsThumbnail9 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-9.jpg"
import windowsThumbnail10 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-10.jpg"
import windowsThumbnail11 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-11.jpg"
import windowsThumbnail12 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-12.jpg"
import windowsThumbnail13 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-13.jpg"
import windowsThumbnail14 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-14.jpg"
import windowsThumbnail15 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-15.jpg"
import windowsThumbnail16 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-16.jpg"
import windowsThumbnail17 from "../images/compressed/window-cleaning/thumbnails/edmonton-window-cleaning-17.jpg"
import chandeliers1 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-1.jpg"
import chandeliers2 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-2.jpg"
import chandeliers3 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-3.jpg"
import chandeliers4 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-4.jpg"
import chandeliers5 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-5.jpg"
import chandeliers6 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-6.jpg"
import chandeliers7 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-7.jpg"
import chandeliers8 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-8.jpg"
import chandeliers9 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-9.jpg"
import chandeliers10 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-10.jpg"
import chandeliers11 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-11.jpg"
import chandeliers12 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-12.jpg"
import chandeliers13 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-13.jpg"
import chandeliers14 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-14.jpg"
import chandeliers15 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-15.jpg"
import chandeliers16 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-16.jpg"
import chandeliers17 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-17.jpg"
import chandeliers18 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-18.jpg"
import chandeliers19 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-19.jpg"
import chandeliers20 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-20.jpg"
import chandeliers21 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-21.jpg"
import chandeliers22 from "../images/compressed/chandelier-cleaning/full-size/edmonton-chandelier-cleaning-22.jpg"
import chandeliersThumbnail1 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-1.jpg"
import chandeliersThumbnail2 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-2.jpg"
import chandeliersThumbnail3 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-3.jpg"
import chandeliersThumbnail4 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-4.jpg"
import chandeliersThumbnail5 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-5.jpg"
import chandeliersThumbnail6 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-6.jpg"
import chandeliersThumbnail7 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-7.jpg"
import chandeliersThumbnail8 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-8.jpg"
import chandeliersThumbnail9 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-9.jpg"
import chandeliersThumbnail10 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-10.jpg"
import chandeliersThumbnail11 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-11.jpg"
import chandeliersThumbnail12 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-12.jpg"
import chandeliersThumbnail13 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-13.jpg"
import chandeliersThumbnail14 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-14.jpg"
import chandeliersThumbnail15 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-15.jpg"
import chandeliersThumbnail16 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-16.jpg"
import chandeliersThumbnail17 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-17.jpg"
import chandeliersThumbnail18 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-18.jpg"
import chandeliersThumbnail19 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-19.jpg"
import chandeliersThumbnail20 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-20.jpg"
import chandeliersThumbnail21 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-21.jpg"
import chandeliersThumbnail22 from "../images/compressed/chandelier-cleaning/thumbnails/edmonton-chandelier-cleaning-thumbnail-22.jpg"
import gutters1 from "../images/compressed/gutter-cleaning/gutter-cleaning-edmonton-tarp.jpg"
import gutters2 from "../images/compressed/gutter-cleaning/gutter-cleaning-edmonton-exterior.jpg"
import guttersThumbnail1 from "../images/compressed/gutter-cleaning/thumbnails/gutter-cleaning-edmonton-tarp.jpg"
import guttersThumbnail2 from "../images/compressed/gutter-cleaning/thumbnails/gutter-cleaning-edmonton-exterior.jpg"
import pressure1 from "../images/compressed/pressure-cleaning/housewash-edmonton.jpg"
import pressure2 from "../images/compressed/pressure-cleaning/pressure-cleaning-edmonton-deck.jpg"
import pressure3 from "../images/compressed/pressure-cleaning/pressure-cleaning-edmonton-bricks.jpg"
import pressure4 from "../images/compressed/pressure-cleaning/edmonton-pressure-cleaning-before.jpg"
import pressure5 from "../images/compressed/pressure-cleaning/edmonton-pressure-cleaning-after.jpg"
import pressureThumbnail1 from "../images/compressed/pressure-cleaning/thumbnails/housewash-edmonton.jpg"
import pressureThumbnail2 from "../images/compressed/pressure-cleaning/thumbnails/pressure-cleaning-edmonton-deck.jpg"
import pressureThumbnail3 from "../images/compressed/pressure-cleaning/thumbnails/pressure-cleaning-edmonton-bricks.jpg"
import pressureThumbnail4 from "../images/compressed/pressure-cleaning/thumbnails/edmonton-pressure-cleaning-before-thumbnail.jpg"
import pressureThumbnail5 from "../images/compressed/pressure-cleaning/thumbnails/edmonton-pressure-cleaning-after-thumbnail.jpg"

const images = [
  {
    src: windows1,
    thumbnail: windowsThumbnail1,
    thumbnailWidth: 320,
    thumbnailHeight: 307,
    caption: "Wiping the frame clean after washing a high exterior window.",
  },
  {
    src: windows2,
    thumbnail: windowsThumbnail2,
    thumbnailWidth: 320,
    thumbnailHeight: 480,
    caption: "We have the equipment to reach your high windows.",
  },
  {
    src: windows3,
    thumbnail: windowsThumbnail3,
    thumbnailWidth: 320,
    thumbnailHeight: 440,
  },
  {
    src: windows4,
    thumbnail: windowsThumbnail4,
    thumbnailWidth: 320,
    thumbnailHeight: 481,
    caption: "Reaching some high windows on an apartment building.",
  },
  {
    src: windows5,
    thumbnail: windowsThumbnail5,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
    caption:
      "The Dr. Squeegee team cleaning exterior windows on an apartment building.",
  },
  {
    src: windows6,
    thumbnail: windowsThumbnail6,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
  },
  {
    src: windows7,
    thumbnail: windowsThumbnail7,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
  },
  {
    src: windows8,
    thumbnail: windowsThumbnail8,
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    caption: "Yes, we can clean your windows in the winter!",
  },
  {
    src: windows9,
    thumbnail: windowsThumbnail9,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
    caption: "Reaching a tall exterior window in the winter.",
  },
  {
    src: windows10,
    thumbnail: windowsThumbnail10,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: windows11,
    thumbnail: windowsThumbnail11,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
    caption:
      "Cleaning an exterior window and setting up for a chandelier cleaning.",
  },
  {
    src: windows12,
    thumbnail: windowsThumbnail12,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
    caption: "Exterior window and chandelier cleaning.",
  },
  {
    src: windows13,
    thumbnail: windowsThumbnail13,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: windows14,
    thumbnail: windowsThumbnail14,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: windows15,
    thumbnail: windowsThumbnail15,
    thumbnailWidth: 320,
    thumbnailHeight: 243,
    caption: "Winter is still a good time to have your windows cleaned.",
  },
  {
    src: windows16,
    thumbnail: windowsThumbnail16,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: "Squeegee cleaning an exterior window.",
  },
  {
    src: windows17,
    thumbnail: windowsThumbnail17,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
    caption: "We also do commercial and apartment building window cleaning.",
  },
]

const images2 = [
  {
    src: chandeliers1,
    thumbnail: chandeliersThumbnail1,
    thumbnailWidth: 320,
    thumbnailHeight: 222,
  },
  {
    src: chandeliers2,
    thumbnail: chandeliersThumbnail2,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: chandeliers3,
    thumbnail: chandeliersThumbnail3,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: chandeliers4,
    thumbnail: chandeliersThumbnail4,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: chandeliers5,
    thumbnail: chandeliersThumbnail5,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
  },
  {
    src: chandeliers6,
    thumbnail: chandeliersThumbnail6,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
  },
  {
    src: chandeliers7,
    thumbnail: chandeliersThumbnail7,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: chandeliers8,
    thumbnail: chandeliersThumbnail8,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
  },
  {
    src: chandeliers9,
    thumbnail: chandeliersThumbnail9,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
  },
  {
    src: chandeliers10,
    thumbnail: chandeliersThumbnail10,
    thumbnailWidth: 320,
    thumbnailHeight: 180,
  },
  {
    src: chandeliers11,
    thumbnail: chandeliersThumbnail11,
    thumbnailWidth: 320,
    thumbnailHeight: 585,
  },
  {
    src: chandeliers12,
    thumbnail: chandeliersThumbnail12,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: chandeliers13,
    thumbnail: chandeliersThumbnail13,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
  },
  {
    src: chandeliers14,
    thumbnail: chandeliersThumbnail14,
    thumbnailWidth: 320,
    thumbnailHeight: 606,
  },
  {
    src: chandeliers15,
    thumbnail: chandeliersThumbnail15,
    thumbnailWidth: 320,
    thumbnailHeight: 569,
    caption: "We have the scaffolding to reach your high chandeliers.",
  },
  {
    src: chandeliers16,
    thumbnail: chandeliersThumbnail16,
    thumbnailWidth: 320,
    thumbnailHeight: 665,
  },
  {
    src: chandeliers17,
    thumbnail: chandeliersThumbnail17,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
  },
  {
    src: chandeliers18,
    thumbnail: chandeliersThumbnail18,
    thumbnailWidth: 320,
    thumbnailHeight: 270,
  },
  {
    src: chandeliers19,
    thumbnail: chandeliersThumbnail19,
    thumbnailWidth: 320,
    thumbnailHeight: 240,
  },
  {
    src: chandeliers20,
    thumbnail: chandeliersThumbnail20,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
  },
  {
    src: chandeliers21,
    thumbnail: chandeliersThumbnail21,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
  },
  {
    src: chandeliers22,
    thumbnail: chandeliersThumbnail22,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
  },
]

const images3 = [
  {
    src: gutters1,
    thumbnail: guttersThumbnail1,
    thumbnailWidth: 320,
    thumbnailHeight: 483,
    caption:
      "We never throw muck and leaves into your yard. We use a tarp or a bucket. We also use ladder standoffs, so our ladders never damage your eavestrough.",
  },
  {
    src: gutters2,
    thumbnail: guttersThumbnail2,
    thumbnailWidth: 320,
    thumbnailHeight: 213,
    caption:
      "We can also clean the outside of your eavestrough / gutter. This is best done at the same time as a house wash.",
  },
]

const images4 = [
  {
    src: pressure1,
    thumbnail: pressureThumbnail1,
    thumbnailWidth: 320,
    thumbnailHeight: 240,
    caption: "Housewash, before and after",
  },
  {
    src: pressure2,
    thumbnail: pressureThumbnail2,
    thumbnailWidth: 320,
    thumbnailHeight: 236,
    caption: "Pressure cleaning a deck, before and after",
  },
  {
    src: pressure3,
    thumbnail: pressureThumbnail3,
    thumbnailWidth: 320,
    thumbnailHeight: 109,
    caption: "Pressure cleaning brick steps, before and after",
  },
  {
    src: pressure4,
    thumbnail: pressureThumbnail4,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
    caption: "Deck before pressure cleaning",
  },
  {
    src: pressure5,
    thumbnail: pressureThumbnail5,
    thumbnailWidth: 320,
    thumbnailHeight: 427,
    caption: "Deck after pressure cleaning",
  },
]

const sideNavItems = [
  {
    url: "/services#window-cleaning",
    title: "Window Cleaning",
  },
  {
    url: "/services#gutter-cleaning",
    title: "Gutter Cleaning",
  },
  {
    url: "/services#chandelier-cleaning",
    title: "Chandelier Cleaning",
  },
  {
    url: "/services#pressure-cleaning",
    title: "Pressure Cleaning",
  },
]

const StyledSideNav = styled.nav`
  padding: 1rem;
  padding-bottom: 1.5rem;
  background-color: ${props => props.theme.colors.backgroundGray};
`

const StyledSideNavList = styled.ul`
  margin: 0;
  padding-left: 1.125rem;
`

const StyledSideNavItem = styled.li`
  margin-top: 1rem;
`

const StyledSideNavLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.textBlack};

  &:hover {
    text-decoration: underline;
  }
`

const ServicesContent = styled.div`
  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
    padding-right: 1rem;
  }
`

const StyledServicesRow = styled(Row)`
  margin-bottom: 3rem;
`

export const StyledServicesAdditionalInfo = styled(Col)`
  ${StyledTertiaryHeading}:first-of-type {
    margin-top: 1.5rem;

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.lg}em) {
      margin-top: 0;
    }
  }
`

const GalleryCol = styled(Col)`
  margin-top: 1.5rem;
`

const SideNav = ({ items }) => {
  return (
    <StyledSideNav>
      <TertiaryHeading>Our Services</TertiaryHeading>
      <StyledSideNavList>
        {items.map(item => (
          <StyledSideNavItem key={item.url}>
            <StyledSideNavLink to={item.url}>{item.title}</StyledSideNavLink>
          </StyledSideNavItem>
        ))}
      </StyledSideNavList>
    </StyledSideNav>
  )
}
const ServicesPage = () => {
  return (
    <ThemeProvider theme={siteTheme}>
      <Layout>
        <Seo
          title="Dr. Squeegee Window Cleaning: Our Services"
          description="Keep your home clean inside and out. We are the Edmonton area's best choice for window, chandelier, gutter, and pressure cleaning."
        />
        <Hero imageUrl={heroBackgroundImage}>
          <Grid>
            <PrimaryHeading className="centered">Our Services</PrimaryHeading>
          </Grid>
        </Hero>
        <Grid>
          <Section>
            <Row>
              <Col xs={12} md={3}>
                <Sticky>
                  <SideNav items={sideNavItems} />
                  <Button to="/estimate" isLightBackground>
                    Get an Estimate
                  </Button>
                </Sticky>
              </Col>
              <Col xs={12} md={9}>
                <StyledServicesRow>
                  <Col xs={12} md={8}>
                    <ServicesContent>
                      <Anchor id="window-cleaning" />
                      <SecondaryHeading>Window Cleaning</SecondaryHeading>
                      <BodyText>
                        When we enter your home, we use{" "}
                        <strong>shoe covers</strong>, and protect your furniture
                        and carpets with <strong>drop sheets</strong>. We take
                        care when moving your furniture and other belongings
                        when gaining access to your window.
                      </BodyText>
                      <BodyText>
                        Our initial cleaning usually includes a{" "}
                        <strong>hand cleaning</strong> of your window with
                        specially designed glass scrapers. This helps to remove
                        things like paint spray, bird and spider droppings,
                        pollution and built-up dirt. This extra step might take
                        longer than our competition, but we know you'll be
                        pleased with the results!
                      </BodyText>
                      <BodyText>
                        While we’re washing your window, we’ll{" "}
                        <strong>brush away dirt</strong>, dust, and any spider
                        webs. We’ll also wipe{" "}
                        <strong>
                          clean your window frames, sills, and screens
                        </strong>
                        .
                      </BodyText>
                    </ServicesContent>
                  </Col>
                  <StyledServicesAdditionalInfo xs={12} md={4}>
                    <TertiaryHeading>Window Cleaning Options</TertiaryHeading>
                    <CheckedItem>
                      Window cleaning inside &amp; outside
                    </CheckedItem>
                    <CheckedItem>Window cleaning outside only</CheckedItem>
                    <CheckedItem>Skylight cleaning</CheckedItem>
                  </StyledServicesAdditionalInfo>
                  <GalleryCol xs={12}>
                    <Gallery
                      images={images}
                      enableImageSelection={false}
                      backdropClosesModal
                      id="window-cleaning-gallery"
                    />
                  </GalleryCol>
                </StyledServicesRow>
                <StyledServicesRow>
                  <Col xs={12} md={8}>
                    <ServicesContent>
                      <Anchor id="chandelier-cleaning" />
                      <SecondaryHeading>Chandelier Cleaning</SecondaryHeading>
                      <BodyText>
                        Winter is the perfect time for you to have your
                        chandeliers cleaned. We can{" "}
                        <strong>remove years of dust</strong>, dirt, and grime
                        from your chandeliers. We will very carefully dismantle,
                        label, clean and reassemble your chandelier. You will be
                        thrilled and amazed to see your chandelier{" "}
                        <strong>sparkle like never before</strong>. We typically
                        charge by the hour for this service.
                      </BodyText>
                    </ServicesContent>
                  </Col>
                  <GalleryCol xs={12}>
                    <Gallery
                      images={images2}
                      enableImageSelection={false}
                      backdropClosesModal
                      id="chandelier-cleaning-gallery"
                    />
                  </GalleryCol>
                </StyledServicesRow>
                <StyledServicesRow>
                  <Col xs={12} md={8}>
                    <ServicesContent>
                      <Anchor id="gutter-cleaning" />
                      <SecondaryHeading>Gutter Cleaning</SecondaryHeading>
                      <BodyText>
                        Protect your best investment by keeping your eavestrough
                        / gutter clean of debris. We never throw muck and leaves
                        into your yard &mdash; we use a{" "}
                        <strong>tarp or a bucket</strong>. We also use{" "}
                        <strong>ladder standoffs</strong>, so our ladders never
                        damage your eavestrough.
                      </BodyText>
                    </ServicesContent>
                  </Col>
                  <GalleryCol xs={12}>
                    <Gallery
                      images={images3}
                      enableImageSelection={false}
                      backdropClosesModal
                      id="gutter-cleaning-gallery"
                    />
                  </GalleryCol>
                </StyledServicesRow>
                <StyledServicesRow>
                  <Col xs={12} md={8}>
                    <ServicesContent>
                      <Anchor id="pressure-cleaning" />
                      <SecondaryHeading>Pressure Cleaning</SecondaryHeading>
                      <BodyText>
                        Clean your house with our low pressure,{" "}
                        <strong>environmentally safe</strong> method. We also
                        pressure clean <strong>decks</strong>,{" "}
                        <strong>fences</strong>, <strong>walkways</strong>,{" "}
                        <strong>driveways</strong>, and more.
                      </BodyText>
                    </ServicesContent>
                  </Col>
                  <StyledServicesAdditionalInfo xs={12} md={4}>
                    <TertiaryHeading>Pressure Cleaning Options</TertiaryHeading>
                    <CheckedItem>Housewash</CheckedItem>
                    <CheckedItem>
                      Pressure cleaning (driveway, walkway, etc.)
                    </CheckedItem>
                  </StyledServicesAdditionalInfo>
                  <GalleryCol xs={12}>
                    <Gallery
                      images={images4}
                      enableImageSelection={false}
                      backdropClosesModal
                      id="pressure-cleaning-gallery"
                    />
                  </GalleryCol>
                </StyledServicesRow>
              </Col>
            </Row>
          </Section>
        </Grid>
      </Layout>
    </ThemeProvider>
  )
}

export default ServicesPage
